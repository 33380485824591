












export interface IAboutItemCfg {
  title: string;
  content: string;
}
import { Component, Vue } from "vue-property-decorator";
@Component
export default class About extends Vue {
  private aboutList: IAboutItemCfg[] = [
    {
      title: "公司名称",
      content: "北京快乐点点科技有限公司",
    },
    {
      title: "公司地址",
      content: "北京市朝阳区安定路39号1幢13层1301E室",
    },
    // {
    //   title: "公司名称",
    //   content: "点点互动科技有限公司",
    // },
  ];
}
